.editor

  // a
  //   @apply no-underline text-blue dark:text-white transition-colors duration-200
  //   @apply before:content-['→'] before:mr-[0.25em]
  a
    @apply underline text-black dark:text-white hover:text-blue dark:hover:text-footer transition-colors duration-200

  h1,
  h2,
  h3,
  h4
    @apply text-headlines font-medium
    @apply mb-[0.25em]


  p ~ h1,
  p ~ h2,
  p ~ h3,
  p ~ h4
    @apply mt-[2em]

  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0

  // ol,
  // ul
  //   @apply max-w-3xl mx-auto text-left

  ul
    li
      @apply mb-3 pl-8 lg:pl-10 relative
      &:before
        content: '— '
        @apply absolute left-0


  &-backlink
    @apply text-copy mb-4 md:mb-6 lg:mb-8
    @apply before:content-['←'] before:mr-[0.25em]
