@font-face {
  font-family: "Roobert";
  src: url("/assets/fonts/Roobert-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("/assets/fonts/Roobert-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
