.slider,
.tns-outer,
.tns-inner,
.tns-slider
  @apply block h-full

.tns-controls button
  @apply absolute z-20
  @apply top-1/2 -translate-y-1/2
  @apply w-16 h-16 md:w-20 md:h-20 lg:w-32 lg:h-32 xl:w-36 xl:h-36 2xl:w-40 2xl:h-40
  @apply overflow-hidden
  @apply text-white
  text-indent: -1111rem

  &[data-controls="prev"],
  &[data-controls="next"]
    @apply cursor-none bg-no-repeat bg-center
    background-size: 36px 36px
    @screen md
      background-size: 68px 68px
    @screen lg
      background-size: 96px 96px
    @screen xl
      background-size: 110px 110px
    @screen 2xl
      background-size: 136px 136px

  &[data-controls="prev"]
    @apply -left-2 md:left-4
    background-image: url("/assets/images/arrow-slider-prev.svg")

  &[data-controls="next"]
    @apply -right-2 md:right-4
    background-image: url("/assets/images/arrow-slider-next.svg")
