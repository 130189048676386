.img
  &-full
    img,
    picture,
    svg
      @apply block w-full h-auto

  &-height
    img,
    picture,
    svg
      @apply block h-full
