.typo
  &-open
    transform-origin: 2px 2px
    .reversed &
      transform-origin: 2px 262px
  &-festival
    transform-origin: 11.1px 222px
    .reversed &
      transform-origin: 11.1px 427px
  &-mainz
    transform-origin: 11.1px 446px
    .reversed &
      transform-origin: 11.1px 648px
  &-24
    transform-origin: 5.4px 665px
    .reversed &
      transform-origin: 5.4px 292.4px
