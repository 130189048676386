.navigation-toggle
  svg
    circle,
    path
      @apply transition-colors duration-200
      .navigation__active &
        @apply fill-green


.navigation
  &--header
    @apply translate-x-full z-[210]
    .navigation__active &
      @apply translate-x-0
  // &-toggle
  //   @apply z-[80]
  //   .navigation__active &
  //     @apply z-[220]

//   &--trigger
//     @apply w-16 h-16 md:h-20 fixed z-30
//     @apply md:translate-y-1.5
//     z-index: 100
//     border: none
//     background: none
//     padding: 0
//     outline: none !important

//     // @apply bg-current

//     &-deco-01,
//     &-deco-02,
//     &-deco-03,
//     &-deco-04
//       @apply content-['']
//       @apply block w-5 h-[3px] md:h-[4px]
//       @apply block w-5 h-[3px] md:h-[4px]
//       @apply absolute top-1/2 left-1/2 -translate-x-1/2
//       @apply transition-all duration-200 origin-center
//       @apply bg-black dark:bg-white

//     &-deco-01
//       @apply rotate-0
//       @apply -translate-y-1.5 md:-translate-y-1.5
//     &-deco-02
//       @apply rotate-0
//       @apply translate-y-1.5 md:translate-y-1.5
//     &-deco-03
//       @apply rotate-0
//     &-deco-04
//       @apply rotate-0

//     .navigation__active &
//       &-deco-01
//         @apply opacity-0 -translate-y-4
//       &-deco-02
//         @apply opacity-0 translate-y-4
//       &-deco-03
//         @apply rotate-45
//       &-deco-04
//         @apply -rotate-45


//   &--header
//     @apply opacity-0 invisible
//     .navigation__active &
//       @apply opacity-100 visible

//   &--header-item
//     @apply opacity-100 translate-y-0
//     // transition: opacity 0.5s, transform 0.5s
//     transition: opacity 0.5s
//     .fade-out &
//       // @apply opacity-0 translate-y-6 md:translate-y-8
//       @apply opacity-0
