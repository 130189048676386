@import 'fonts';
@import 'properties';

@import 'tailwindcss/base';
@import 'custom-base.scss';

@import 'tailwindcss/components';
@import 'custom-components.scss';

@import 'tailwindcss/utilities';
@import 'custom-utilities.scss';

@layer components {
  .eye-animate-bg {
    fill: #fff;
  }
  .eye-animate-cover {
    fill: #fff;
    stroke: #000;
  }
  .eye-animate-lens {
    fill: #fff;
    stroke: #000;
  }

  .darkmode-switcher-svg path,
  .darkmode-toggle {
    fill: #000;
  }

  .dark {
    .eye-animate-bg {
      fill: #000;
    }
    .eye-animate-cover {
      fill: #000;
      stroke: #fff;
    }
    .eye-animate-lens {
      fill: #000;
      stroke: #fff;
    }

    .darkmode-switcher-svg path,
    .darkmode-toggle {
      fill: #fff;
    }

    // .navigation-toggle-bright,
    // .darkmode-eye-open {
    //   display: none;
    // }
    // .navigation-toggle-dark,
    // .darkmode-eye-closed {
    //   display: block;
    // }
  }
}

// @import 'artists';
@import 'base';
@import 'cursor';
@import 'editor';
// @import 'footer';
// @import 'header';
@import 'hero';
@import 'img';
// @import 'linkteaser';
@import 'navigation';
// @import 'projects';
@import 'reveal';
@import 'typo';
@import 'slider';

@import "./vendor/tiny-slider.css"
