:any-link,
button
  @apply cursor-none


.cursor
  svg
    @apply transition-transform duration-150 origin-top-left
    // @apply text-black dark:text-white

.cursor-active
  svg
    @apply rotate-90
    // @apply text-black dark:text-white
    // @apply -scale-x-100
    // @apply text-blue
