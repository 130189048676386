.hero
  svg
    // @apply block w-auto max-w-full h-full
    @apply absolute top-0 left-0 h-full w-auto max-w-full

  // .typo
  //   &-open
  //     transform-origin: 2px 2px
  //   &-festival
  //     transform-origin: 11.1px 222px
  //   &-mainz
  //     transform-origin: 11.1px 446px
  //   &-24
  //     transform-origin: 5.4px 665px

  // &.scroll-reversed
  //   .typo
  //     &-open
  //       transform-origin: 2px 262px
  //     &-festival
  //       transform-origin: 11.1px 427px
  //     &-mainz
  //       transform-origin: 11.1px 648px
  //     &-24
  //       transform-origin: 5.4px 292.4px
